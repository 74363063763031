import React from 'react';

import Layout from '../components/Layout';
import WomenSignUp from '../components/WomenSignUp';

import '../styles/global.scss';

const WomenTemplate = () => (
  <Layout>
    <WomenSignUp />
  </Layout>
);

export default WomenTemplate;
