import React from 'react';

interface PropType {
    formId: string,
    id: string,
}

interface StateType {
    script: any;
}

class HubspotMediaForm extends React.Component<PropType, StateType>
{
    constructor(props: PropType) {
        super(props);
    }
    
    componentDidMount() {
        const script = document.createElement('script');

        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if ((window as any).hbspt) {
                (window as any).hbspt.forms.create({
                    portalId: '4788214',
                    formId: this.props.formId,  //'bee6250a-464b-4f3f-afb7-b2775a8099ef',
                    target: '#hubspotForm-' + this.props.id
                });
            }
        });

        this.setState({ script });
    }

    componentWillUnmount() {
        this.state.script.removeEventListener('load', () => { });
    }

    render() {
        return (
            <React.Fragment>
                <div id={this.props.id} >
                    <div id={`hubspotForm-${this.props.id}`}></div>
                </div>
            </React.Fragment>
        );
    }
}

export default HubspotMediaForm;