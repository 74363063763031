import React from 'react';

import HubspotMediaForm from '../../components/Hubspot/hubspotForm'

import './Hubspot.scss';
import './WomenSignUp.scss';

const WomenSignUp = () => {
    return (
      <div className='women-sign-up'>
        <div className='women-sign-up--mobileBackground' />
        <div className='women-sign-up--form'>
          <div className="women-sign-up--rainbowStrip" />
          <div className='women-sign-up--form-content'>
            <h1>Welcome to the Collective.</h1>
            <p>Sign up to be notified for our first Women's Cannabis Collective online merch drop- a limited-edition line designed to celebrate all women and our shared love for the plant.</p>
            <HubspotMediaForm formId='eb303771-bcfd-432d-b93b-c64bdfdfd1f9' id='4788214' />
          </div>
        </div>
      </div>
    );
};

export default WomenSignUp;